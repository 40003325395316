<template>
  <aside class="Layout__aside">
    <NewsSearchBar v-if="isAuthorPage" />
    <template v-if="pageBlogPosts.length">
      <h2 class="Layout__aside__title">Latest posts</h2>
      <AsidePosts :posts="pageBlogPosts" />
    </template>
    <h2 class="Layout__aside__title">Categories</h2>
    <CategoryCloud />
  </aside>
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
import { mapToAsidePosts } from "~/mappers/components";
import { IAsidePostsComponent } from "~/types/components";
import { useContentfulQuery } from "~/services";
import { IAsidePostsResponse } from "~/types/contentful/responses";

const route = useRoute();
const contenfulService = useContentfulQuery();
const asidePostResponse: IAsidePostsResponse[] =
  await contenfulService.getAsidePosts({ limit: 5 });

const pageBlogPosts = computed((): IAsidePostsComponent[] => {
  return mapToAsidePosts(asidePostResponse, false);
});

const isAuthorPage = computed((): boolean => {
  return !!route.params.author;
});
</script>
